<template>
  <div class="page1">
    <div class="breadcrumb">
      <div style="text-align: right">
        <el-button @click="$router.go(-1)" type="primary">返回</el-button>
      </div>
    </div>
    <!--主体内容-->
    <div class="content">
      <div v-for="(item, index) in helpList" :key="index" class="header_wraper">
        <div class="title-box fw600" @click="showToggle(index)">
          <img
            :src="
              item.isSubShow
                ? require('../../../assets/img/parkiconArrowBottom.png')
                : require('../../../assets/img/parkiconArrowRight.png')
            "
            alt=""
            :class="item.isSubShow ? 'isBottom' : ''"
          />
          <span>{{ item.title }}</span>
        </div>
        <!-- </div> -->
        <div v-if="item.isSubShow" class="header_box bgFFF" :key="index">
          <div key="one" v-if="index == 0">
            <div class="Wrapper">
              <el-form
                label-position="left"
                :inline="true"
                label-width="auto"
                :model="formInline"
                style="width: calc(100% - 376px); float: left; overflow: hidden"
                :ref="'form'"
              >
                <div class="title-text fw600">道路信息</div>
                <el-form-item label="道路层级:" prop="slaveRelation">
                  <span style="line-height: 40px; color: #666">{{
                    ["道路", "道路", "路段"][formInline.slaveRelation]
                  }}</span>
                </el-form-item>
                <el-form-item v-if="formInline.projectBatch" label="项目批次" prop="projectBatch">
                  <span style="line-height: 40px; color: #666">{{ formInline.projectBatch }}</span>
                </el-form-item>
                <el-form-item
                  label="关联道路:"
                  :prop="formInline.slaveRelation == 2 ? 'parentId' : ''"
                  v-if="formInline.slaveRelation == 2"
                >
                  <span style="line-height: 40px; color: #666">{{
                    formInline.parentParkName
                  }}</span>
                </el-form-item>
                <el-form-item :label="slaveRelationDesc + '名称:'" prop="parkName">
                  <span style="line-height: 40px; color: #666">{{ formInline.parkName }}</span>
                </el-form-item>
                <el-form-item :label="slaveRelationDesc + '编码:'" prop="parkAlias">
                  <span style="line-height: 40px; color: #666">{{ formInline.parkAlias }}</span>
                </el-form-item>
                <el-form-item
                  :label="slaveRelationDesc + '物理类型:'"
                  prop="parkType"
                  style="display: inline-block"
                >
                  <span style="line-height: 40px; color: #666">{{ formInline.parkTypeName }}</span>
                </el-form-item>
                <el-form-item label="系统编码: " prop="parkCode">
                  <span style="line-height: 40px; color: #666">{{ formInline.parkCode }}</span>
                </el-form-item>
                <el-form-item
                  :label="slaveRelationDesc + '设备类型:'"
                  prop="parkEquipmentType"
                  v-if="$route.query.parkTypeName != '封闭车场'"
                >
                  <span style="line-height: 40px; color: #666">{{
                    formInline.parkEquipmentTypeName
                  }}</span>
                </el-form-item>
                <el-form-item
                  label="泊位数:"
                  :prop="formInline.slaveRelation != 1 ? 'amount' : ''"
                  v-if="formInline.slaveRelation != 1"
                >
                  <span style="line-height: 40px; color: #666">{{ formInline.amount }}</span>
                </el-form-item>
                <el-form-item label="区域:" prop="areaId">
                  <span style="line-height: 40px; color: #666">{{ formInline.areaName }}</span>
                </el-form-item>
                <el-form-item label="经纬度:" prop="longitude">
                  <span style="line-height: 40px; color: #666">{{ lnglat }}</span>
                </el-form-item>
                <el-form-item label="是否支持充电: " prop="parkCode">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.chargeable == 0 ? "否" : formInline.chargeable == 1 ? "是" : ""
                  }}</span>
                </el-form-item>
                <el-form-item label="地址:" prop="parkAddress" class="address-box">
                  <span style="line-height: 40px; color: #666">{{ formInline.parkAddress }}</span>
                </el-form-item>
                <el-form-item label="接入方式: " prop="parkAccessTypeName">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.parkAccessTypeName
                  }}</span>
                </el-form-item>
                <div class="title-text fw600">道路运营信息</div>
                <el-form-item label="所属商户:" prop="businessOwner">
                  <span style="line-height: 40px; color: #666">{{ formInline.operationName }}</span>
                </el-form-item>
                <el-form-item label="是否商业车场:" prop="businessType">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.businessTypeName
                  }}</span>
                </el-form-item>
                <el-form-item label="合作模式:" prop="cooperationModel">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.cooperationModelName
                  }}</span>
                </el-form-item>
                <el-form-item label="支付方式:" prop="payMode">
                  <span style="line-height: 40px; color: #666">{{ formInline.payModeName }}</span>
                </el-form-item>
                <el-form-item label="商用状态:" prop="commercialStatus">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.commercialStatusName
                  }}</span>
                </el-form-item>
                <el-form-item label="道路状态:" prop="parkState">
                  <span style="line-height: 40px; color: #666">{{ formInline.parkStateName }}</span>
                </el-form-item>
                <el-form-item
                  label="计费类型:"
                  prop="scopeId"
                  v-if="formInline.parkType != 3"
                  style="vertical-align: top"
                >
                  <span style="line-height: 40px; color: #666">{{ formInline.scopeName }}</span>
                </el-form-item>
                <!-- <el-form-item label="场内支付:" prop="innerPayable">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.innerPayable == 1 ? "支持" : "不支持"
                  }}</span>
                </el-form-item> -->
                <el-form-item label="扩展支付场景:" prop="innerPayable">
                  {{ getinnerPayable(formInline.innerPayable) }}
                </el-form-item>
                <el-form-item
                  label="自主停车:"
                  prop="innerPayable"
                  v-if="formInline.innerPayable == 3"
                >
                  {{ formInline.configName }}
                </el-form-item>
                <el-form-item
                  v-if="formInline.innerPayable == 1"
                  label="免费驶离时长: "
                  prop="innerPayTime"
                >
                  <span v-if="formInline.innerPayTime" style="line-height: 40px; color: #666"
                    >{{ formInline.innerPayTime }}分钟</span
                  >
                </el-form-item>
                <el-form-item
                  label="收费规则描述: "
                  prop="thirdFeeDesc"
                  class="rule-box"
                  v-if="formInline.thirdFeeDesc"
                >
                  <span style="line-height: 40px; color: #666">{{ formInline.thirdFeeDesc }}</span>
                </el-form-item>

                <div class="title-text fw600">{{ slaveRelationDesc }}相关信息</div>
                <el-form-item label="泊位分布: " prop="berthDeploy">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.berthDeploy == 1 ? "单侧" : formInline.berthDeploy == 2 ? "双侧" : ""
                  }}</span>
                </el-form-item>

                <el-form-item label="所在道路长度: " prop="roadLength">
                  <span v-if="formInline.roadLength" style="line-height: 40px; color: #666"
                    >{{ formInline.roadLength }}米</span
                  >
                </el-form-item>
                <el-form-item label="是否承包:" prop="contract" v-if="formInline.parkType == 3">
                  <span style="line-height: 40px; color: #666">{{
                    formInline.contract == 0 ? "是" : "否"
                  }}</span>
                </el-form-item>
                <el-form-item
                :label="slaveRelationDesc + '开放时间:'"
                  prop="openStartTime"
                  v-if="formInline.parkType == 3"
                >
                  <span style="line-height: 40px; color: #666">{{
                    formInline.openStartTime + "~" + formInline.openStopTime
                  }}</span>
                </el-form-item>
              </el-form>
              <div class="uploadPic">
                <img v-if="parkImgList[0]" :src="parkImgList[0]" class="avatar2" />
                <img v-if="parkImgList[1]" :src="parkImgList[1]" class="avatar2" />
              </div>
            </div>
          </div>
          <div key="two" v-else-if="index == 1">
            <park-comment :datas="spinner"></park-comment>
          </div>
          <div key="three" v-else>
            <owner-comment :datas="spinner"></owner-comment>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import // dateFormat
"../../../common/js/public.js";
import parkComment from "./detailComponent/parkComment";
import ownerComment from "./detailComponent/ownerComment";
export default {
  name: "hello",
  data() {
    return {
      parkImgList: [],
      helpList: [
        {
          title: "道路详情",
          isSubShow: true,
        },
        {
          title: "车主评价详情",
          isSubShow: false,
        },
      ],
      curCount: 0,
      spinner: [],
      openTime: "",
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      parkEquipmentType: [],
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      slaveRelationDesc: "道路", // 区分道路、路段描述文案
      formInline: {
        parkName: "",
        areaId: "",
        parkCode: "",
        slaveRelation: 1,
        innerPayable: "",
        parentId: "",
        parkAddress: "",
        imageUrl: "",
        amount: "",
        parkType: "",
        businessOwner: "",
        bootTime: new Date(),
        parkState: "",
        mapGrade: "",
        payMode: "",
        cooperationModel: "",
        commercialStatus: 1,
        longitude: "",
        latitude: "",
        businessType: "",
        scopeId: "",
        contract: "",
        openStartTime: "",
        openStopTime: "",
        parkEquipmentType: "",
        access: "", // 接入方式
        projectBatch: "", // 项目批次
      },
    };
  },
  methods: {
    getinnerPayable(innerPayable) {
      // 自主停车和场中支付
      if (this.formInline.innerPayable == 1 && this.formInline.selfParkingId > 0) {
        return "场中支付,自主停车";
        // 自主停车
      } else if (this.formInline.innerPayable == 2 && this.formInline.selfParkingId > 0) {
        return "自主停车" + ":" + this.formInline.configName;
        // 场中支付
      } else if (this.formInline.innerPayable == 1 && this.formInline.selfParkingId == 0) {
        return "场中支付";
      } else {
        return "无";
      }
    },
    showToggle(num) {
      this.curCount = num;
      this.helpList.forEach((item, index) => {
        if (index == num) {
          item.isSubShow = !item.isSubShow;
        } else {
          item.isSubShow = false;
        }
      });
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.formInline = data;
          this.areaName = data.areaName;
          this.businessName = data.operationName;
          this.lnglat =
            data.longitude && data.latitude
              ? data.longitude / 1000000 + "," + data.latitude / 1000000
              : "";
          this.openTime = [
            new Date(this.formInline.openStartTime),
            new Date(this.formInline.openStopTime),
          ];
          this.slaveRelationDesc = data.slaveRelation == 2 ? "路段" : "道路";
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let imgItem = res.value ? res.value : "";
          this.parkImgList.push(imgItem);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImgBefore(parkId) {
      this.parkImgList = [];
      let url = "/acb/2.0/park/getParkImageIds/";
      this.$axios.get(url, { data: { parkId } }).then((res) => {
        if (res.value.length) {
          res.value.forEach((item) => {
            this.getParkImg(item);
          });
        }
      });
    },
    getSpinnerData() {
      let url = "/acb/2.0/memberParkEvaluation/spinner";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.spinner = res.value.parkEvalutionEnum || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
  },
  components: {
    parkComment,
    ownerComment,
  },
  created() {
    this.getSpinnerData();
    this.getParkImgBefore(this.$route.query.parkId);
    this.getParkDetail(this.$route.query.parkId);
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.Wrapper {
  overflow: hidden;
  width: 100%;
  padding-right: 50px;
  box-sizing: border-box;
}

.breadcrumb {
  margin: 8px 24px;
}

.content {
  overflow: hidden;
  border-radius: 4px;
  border: 0px solid #d9d9d9;
  padding-bottom: 20px;
}

.header_title {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background: #0f6eff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-indent: 12px;
}

.header_wraper {
  width: 100%;
}

.header {
  width: 100%;
  height: 42px;
  line-height: 42px;
  background: #f9f9f9;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  padding-left: 30px;
  position: relative;
  cursor: pointer;
}

.header:nth-child(1) {
  border-top: none;
}

.header i {
  -webkit-transform-origin: 50% 50%;
  margin-right: 2px;
  display: inline-block;
  position: absolute;
  top: 13px;
  left: 10px;
}

.cur1 {
  -webkit-transition: all 0.1s linear;
  -webkit-transform: rotate(90deg);
  top: 14px !important;
}

.header_box {
  width: 100%;
  // border: 1px solid #dcdcdc;
  box-sizing: border-box;
  border-top: none;
  padding: 0 10px 10px 17px;
}

.uploadPic {
  float: right;
  // margin-left: 150px;
  width: 368px;
  height: 225px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
    margin-bottom:10px;
  }
}

.title-box {
  width: 100%;
  display: flex;
  cursor: pointer;
  padding-bottom: 20px;
  img {
    width: 7px;
    height: 10px;
    margin-right: 6px;
    position: relative;
    top: 2px;

    &.isBottom {
      width: 10px;
      height: 7px;
      top: 5px;
    }
  }

  span {
    font-size: 15px;
    color: #2F2F51;
  }
}

.header_wraper {
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 16px;
}

.fw600 {
  font-weight: 600;
}

.title-text {
  position: relative;
  font-size: 14px;
  color: #2F2F51;
  padding-left: 12px;
  margin: 8px 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 15px;
    background: #0C66FF;
  }
}

/deep/ {
  .el-form-item {
    width: 48%;
    // display: inline-block;
    // padding-right: 4%;
    box-sizing: border-box;


    .el-form-item__label {
      color: #98A9BF;
    }
  }

  .el-form-item__label-wrap {
    margin-left: 0px !important;
  }

  .el-form-item__content {
    margin-left: 0px !important;
  }

  .address-box .el-form-item__content {
    margin-left: 44px !important;
  }

  .rule-box .el-form-item__content {
    margin-left: 100px !important;
  }

  .el-form--inline .el-form-item__content {
    display: block;
  }
}
</style>
<style>
.dialog_button {
  width: 100%;
  height: 40px;
}
</style>
